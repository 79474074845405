<template>
  <v-btn @click="exportToExcel" :disabled="disabled" color="primary">Exportar para Excel</v-btn>
</template>

<script>
import * as XLSX from 'xlsx';
import { mapActions, mapState } from "vuex";

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
    fileName: {
      type: String,
      default: 'dados.xlsx',
    },
    sheetName: {
      type: String,
      default: 'Sheet1',
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState("solicitation", ["dataExcel"]),
  },
  methods: {
    ...mapActions("solicitation", ["loadSolicitationExcel"]),
    getNestedValue(obj, path) {
      return path.split('.').reduce((value, key) => value[key], obj);
    },
    async exportToExcel() {
      await this.loadSolicitationExcel({
        page: 1,
        per_page: -1,
        search: this.filter.search,
        search_column: this.filter.search_column,
        sort_by: this.sortBy,
        sort_order: this.sortOrder,
      }).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });

      const data = this.dataExcel;
      const colunasFormatadas = {
        friendly_id: 'ID',
        "company.name": 'Empresa',
        user: 'Criado Por',
        "requester.nome": 'Solicitante',
        created_at_format: 'Data de Abertura',
        sla_deadline_validate_format: 'Responder até',
        sla_deadline_finish_format: 'Finalizar até',
        description: 'Tipo Solicitação',
        "status.display_name": 'Status',
        info_solicitation: 'Info Solicitação',
      };

      // Filtra e formata os dados
      const dadosFiltrados = data.map(objeto => {
        const novoObjeto = {};
        Object.keys(colunasFormatadas).forEach(colunaOriginal => {
          const colunaFormatada = colunasFormatadas[colunaOriginal];
          novoObjeto[colunaFormatada] = this.getNestedValue(objeto, colunaOriginal);
        });
        return novoObjeto;
      });

      // Gera o nome do arquivo com a data atual
      const dataFormatada = new Date().toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' });
      const nomeArquivo = `${dataFormatada}_solicitation.xlsx`;

      // Cria a planilha Excel
      const ws = XLSX.utils.json_to_sheet(dadosFiltrados);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, this.sheetName);
      XLSX.writeFile(wb, nomeArquivo);
    },
  },
};
</script>