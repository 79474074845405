<template>
  <v-container class="mt-0 pt-0">
    <v-dialog
        v-model="justifyDialog"
        class="mx-auto"
        persistent
        max-width="70%"
    >
      <v-card outlined>
        <v-card-title>Digite a justificativa</v-card-title>
        <v-card-text>
          <v-textarea
              clearable
              autofocus
              v-model="justify"
              outlined
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue-grey darken-2"
              text
              @click="closeDialog"
              :disabled="loading"
          >
            Fechar
          </v-btn>
          <v-btn
              color="primary"
              @click="save()"
              text
              :loading="loading"
              :disabled="loading"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    justifyDialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      justify: "",
    }
  },
  methods: {
    closeDialog: function () {
      this.clearField();
      this.$emit('closeDialog', false);
    },

    save: function () {
      this.$emit('save', this.justify);
      this.clearField();
    },

    clearField: function () {
      this.justify = "";
    }
  }
};
</script>
